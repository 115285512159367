<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" :max-items="renderMethod.itemsPerPage" :data="users">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div id="data-list-search-rank" style="width: 80px; height: 40px; padding-top: 0;">
          <vs-dropdown class="flex flex-wrap-reverse">
            <a class="flex items-center" href="#">
              <span class="mr-2">{{ factorToText(renderMethod.factor) }}</span>
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="renderMethod.factor='account_id'">아이디</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='nickname'">별명</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='email'">이메일</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='hp'">전화</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='register_date'">가입일</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div style="margin-bottom:15px; padding-top: 0;">
          <vs-input v-on:keyup.enter="searchUserList()" class="inputx" placeholder="회원 전체 검색" v-model="renderMethod.inputText"/>
        </div>
        <div class="vs-table--search" style="margin: 0px 15px">
          <vs-button
            ref="loadableButton"
            id="button-with-loading"
            color="primary"
            type="border"
            class="p-2 cursor-pointer vs-con-loading__container"
            @click="searchUserList()"
          >검색</vs-button>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
          <div
            class="p-4 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span
              class="mr-2"
            >{{ ((currentx - 1) * renderMethod.itemsPerPage) + 1 }} - {{ numOfUsers >= currentx * renderMethod.itemsPerPage ? (currentx * renderMethod.itemsPerPage) : numOfUsers }} of {{ numOfUsers }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="changeItemPerPage(4)">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(15)">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(20)">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="nick">아이디</vs-th>
        <vs-th sort-key="id">닉네임(별명)</vs-th>
        <vs-th sort-key="email">이메일</vs-th>
        <vs-th sort-key="regdate">전화</vs-th>
        <vs-th sort-key="writer">가입일</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td><div v-on:click="showUserInfo(tr.id)" style="font-weight: bold">{{ tr.id }}</div></vs-td>
            <vs-td>{{ tr.nick }}</vs-td>
            <vs-td>{{ tr.email }}</vs-td>
            <vs-td>{{ tr.hp }}</vs-td>
            <vs-td>{{ tr.regdate }}</vs-td>

            <!-- <vs-td>
                <vs-progress :percent="Number(tr.popularity)" :color="getPopularityColor(Number(tr.popularity))" class="shadow-md" />
              </vs-td>

              <vs-td>
                <vs-chip :color="getOrderStatusColor(tr.order_status)" class="product-order-status">{{ tr.order_status }}</vs-chip>
              </vs-td>

              <vs-td>
                <p class="product-price">${{ tr.price }}</p>
            </vs-td>-->
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <div>
        <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div>

     <!-- Start: 계정 수정 팝업 -->
    <vs-popup classContent="popup-example" title="계정 수정" :active.sync="editUserModalActive">
      <div class="edit-user-form">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>IP</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <span class="w-full" placeholder="없음">{{ userModalData.editUserQueryData.ip }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>닉네임(별명)</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" placeholder="없음" v-model="userModalData.editUserQueryData.nick"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>아이디</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" placeholder="없음" v-model="userModalData.editUserQueryData.account_id"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>암호</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" type="password" v-model="userModalData.editUserQueryData.password"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>이메일</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" placeholder="없음" type="email" v-model="userModalData.editUserQueryData.email"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>전화</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" placeholder="없음" v-model="userModalData.editUserQueryData.hp"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>항목</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <div class="select_section">
              <v-select style="width: 150px" placeholder="승인/거부" label="text"
                v-model="userModalData.editUserQueryData.grant" :clearable="false"
                :options="userModalData.optionData.grantOption"/>
              <!-- <vs-select style="width: 100px; margin-right: 15px; display: inline-block" v-model="userModalData.editUserQueryData.grant">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item,index) in userModalData.optionData.grantOption"
                />
              </vs-select> -->
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="editUser()">수정</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mb-2"
              @click="editUserModalInfoCancelModalActive=true"
            >취소</vs-button>
          </div>
          <div class="demo-alignment2">
            <vs-popup classContent="popup-example2" title="수정 취소" :active.sync="editUserModalInfoCancelModalActive">
              <p style="padding: 20px">취소하시겠습니까?</p>
              <div style="padding:20px; display: inline-block;">
              <vs-button @click="resetQueryDataOfUserEditModal(); editUserModalInfoCancelModalActive=false; editUserModalActive=false;" color="primary" type="filled">예</vs-button>
              </div>
              <div style="margin-bottom: 20px; display: inline-block;">
              <vs-button @click="editUserModalInfoCancelModalActive=false" color="primary" type="filled">아니오</vs-button>
              </div>
            </vs-popup>
          </div>
        </div>
      </div>
    </vs-popup>
    <!-- End: 계정 수정 팝업 -->

  </div>
</template>

<script>

export default {
  components: {
    
  },
  data() {
    return {
      editUserModalActive: false,
      editUserModalInfoCancelModalActive: false,
      userModalData: {
        editUserQueryData: {
          ip: '',
          nick: '',
          target_account_id: '',
          account_id: '',
          password: '',
          email: '',
          hp: '',
          grant: '',
        },
        editUserQueryDataOrigin: {
          ip: '',
          nick: '',
          account_id: '',
          password: '',
          email: '',
          hp: '',
          grant: '',
        },
        optionData: {
          grantOption: [
            { text: "승인", value: "1" },
            { text: "거부", value: "0" }
          ]
        },
      },
      users: [],
      currentx: 1,
      numOfUsers: 0,
      numOfPages: 0,
      searchText: "",
      renderMethod: {
        startRow: 0,
        itemsPerPage: 10,
        factor: "nickname",
        inputText: ""
      },
      isMounted: false,
    };
  },
  beforeRouteLeave (to, from, next) {
    this.editUserModalActive = false;
    this.editUserModalInfoCancelModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    currentx: function() {
                                    // currentx-1 is value before changed by watch: -> currentx:
      this.renderMethod.startRow = (this.currentx-1) * this.renderMethod.itemsPerPage
      this.getUserList()
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
  },
  methods: {
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum
      this.getUserList()
    },
    
    factorToText(menu) {
      let menuText;
      switch (menu) {
        case "nickname":
          menuText = "별명";
          break;
        case "account_id":
          menuText = "아이디";
          break;
        case "email":
          menuText = "이메일";
          break;
        case "hp":
          menuText = "전화";
          break;
        case "register_date":
          menuText = "가입일";
          break;
      }
      return menuText;
    },
    
    searchUserList() {

      this.resetPagePosition()
      this.updateSearchText()
      this.getUserList()
    },
    
    getUserList() {

      this.$vs.loading({
        container: "#button-with-loading",
        scale: 0.6
      });
      const thisIns = this;
      
      this.$http
        .post("/api/manageUsers/commonlist", thisIns.buildSearchParam())
        .then(function(response) {

          thisIns.numOfUsers = response.data[0][0].user_count
          thisIns.numOfPages = Math.ceil(response.data[0][0].user_count / thisIns.renderMethod.itemsPerPage)
          thisIns.users = response.data[1]

          thisIns.$vs.loading.close("#button-with-loading > .con-vs-loading");
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },

    buildSearchParam() {

      var param = {};

      param['startRow'] = this.renderMethod.startRow
      param['itemsPerPage'] = this.renderMethod.itemsPerPage
      param['factor'] = this.renderMethod.factor
      param['searchText'] = this.searchText

      return param
    },

    resetPagePosition() {

      this.currentx = 1
    },

    updateSearchText() {
      this.searchText = this.renderMethod.inputText
    },
    
    isExistUser(account_id, func) {

      var thisObj = this
      
      thisObj.$http
        .post("/api/manageUsers/isExistUser", {account_id: account_id})
        .then(function(response) {
          
          console.log("exist response: "+response.data.exist)
          if(response.data.exist == "0") {
            
            func(false)
          }
          else if(response.data.exist == "1") {

            func(true)
          }
          else {

            thisObj.$vs.notify({
              title:'계정 생성 실패',
              text:"알 수 없는 오류로 인해 계정 생성에 실패했습니다.",
              color:'danger'})

            console.log("invalid data: "+response.data.exist)
          }
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
          }
          thisObj.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },

    mapUserInfoAtEditModal(userInfo) {

      this.userModalData.editUserQueryData.ip = userInfo.ip
      this.userModalData.editUserQueryData.nick = userInfo.nick
      this.userModalData.editUserQueryData.account_id = userInfo.id
      this.userModalData.editUserQueryData.password = ''
      this.userModalData.editUserQueryData.email = userInfo.email
      this.userModalData.editUserQueryData.hp = userInfo.hp
      this.userModalData.editUserQueryData.grant = this.userModalData.optionData.grantOption.find(data => data.value == userInfo.del_frag);//; ? userInfo.del_frag : ""

      this.userModalData.editUserQueryData.ip = userInfo.ip
      this.userModalData.editUserQueryDataOrigin.nick = userInfo.nick
      this.userModalData.editUserQueryDataOrigin.account_id = userInfo.id
      this.userModalData.editUserQueryDataOrigin.password = ''
      this.userModalData.editUserQueryDataOrigin.email = userInfo.email
      this.userModalData.editUserQueryDataOrigin.hp = userInfo.hp
      this.userModalData.editUserQueryDataOrigin.grant = this.userModalData.optionData.grantOption.find(data => data.value == userInfo.del_frag);//userInfo.del_frag ? userInfo.del_frag : ""
    },
// [ undefined,
//   'k402600',
//   'k402600@naver.com',
//   '010-2506-4391',
//   undefined,
//   undefined,
//   undefined,
//   undefined,
//   '',
//   'k402600' ]

    showUserInfo(account_id) {

      var thisObj = this;

      thisObj.$http
          .post("/api/manageUsers/getCommon", {account_id: account_id})
          .then(function(response) {

            console.log(response.data)
            thisObj.mapUserInfoAtEditModal(response.data)
            thisObj.editUserModalActive = true
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },

    editUser() {

      var thisObj = this

      if(!thisObj.isPassedIntegrityVerification()) {
        return;
      }

      // if account id edited.
      if(thisObj.userModalData.editUserQueryDataOrigin.account_id != thisObj.userModalData.editUserQueryData.account_id) {

        thisObj.isExistUser(thisObj.userModalData.editUserQueryData.account_id, function(exist) {

          if(exist) {
            
            thisObj.$vs.notify({
              title:'중복 아이디',
              text:"중복된 아이디입니다.",
              color:'warning'})

            return
          }

          thisObj.runEditUser()
        })
      }
      else {
          thisObj.runEditUser()
      }
    },

    runEditUser() {

      var thisObj = this

      thisObj.userModalData.editUserQueryData.target_account_id = thisObj.userModalData.editUserQueryDataOrigin.account_id;
      thisObj.userModalData.editUserQueryData.grant = thisObj.userModalData.editUserQueryData.grant.value;

      thisObj.$http
          .post("/api/manageUsers/editCommon", thisObj.userModalData.editUserQueryData)
          .then(function(response) {
            
            if(response.data === "success") {

              thisObj.$vs.notify({
                title:'계정 수정 완료',
                text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 완료.",
                color:'success'})
              
              thisObj.editUserModalActive = false
              thisObj.resetQueryDataOfUserEditModal()
              thisObj.getUserList()
            }
            else {
              thisObj.$vs.notify({
                title:'계정 수정 실패',
                text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 실패.",
                color:'danger'})
            }

            thisObj.$vs.loading.close("#button-with-loading > .con-vs-loading");
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },

    resetQueryDataOfUserEditModal() {
      this.userModalData.editUserQueryData.nick
      = this.userModalData.editUserQueryData.account_id
      = this.userModalData.editUserQueryData.password
      = this.userModalData.editUserQueryData.email
      = this.userModalData.editUserQueryData.hp
      = this.userModalData.editUserQueryData.tailname
      = this.userModalData.editUserQueryData.department
      = this.userModalData.editUserQueryData.level
      = this.userModalData.editUserQueryData.showWriterList
      = this.userModalData.editUserQueryData.showStatistics
      = this.userModalData.editUserQueryData.grant
      = ''
    },

    isPassedIntegrityVerification() {
      var nickChanged = this.userModalData.editUserQueryData.nick !== this.userModalData.editUserQueryDataOrigin.nick
      var accountIdChanged = this.userModalData.editUserQueryData.account_id !== this.userModalData.editUserQueryDataOrigin.account_id
      var passwordChanged = this.userModalData.editUserQueryData.password !== this.userModalData.editUserQueryDataOrigin.password
      var emailChanged = this.userModalData.editUserQueryData.email !== this.userModalData.editUserQueryDataOrigin.email
      var hpChanged = this.userModalData.editUserQueryData.hp !== this.userModalData.editUserQueryDataOrigin.hp
      var grantChanged = this.userModalData.editUserQueryData.grant !== this.userModalData.editUserQueryDataOrigin.grant

      var fillDataCheck = (nickChanged ? (this.userModalData.editUserQueryData.nick && this.userModalData.editUserQueryDataOrigin.nick != '') : true)
              && (accountIdChanged ? (this.userModalData.editUserQueryData.account_id && this.userModalData.editUserQueryDataOrigin.account_id != '') : true)
              && (emailChanged ? (this.userModalData.editUserQueryData.email && this.userModalData.editUserQueryDataOrigin.email != '') : true)
              && (hpChanged ? (this.userModalData.editUserQueryData.hp && this.userModalData.editUserQueryDataOrigin.hp != '') : true)
              && (grantChanged ? (this.userModalData.editUserQueryData.grant && this.userModalData.editUserQueryDataOrigin.grant != '') : true)

      var regExpNick = /[0-9]|[a-z]|[A-Z]|[가-힣]/;
      var nickCheck = (this.userModalData.editUserQueryData.nick.length >= 2 && this.userModalData.editUserQueryData.nick.length <= 12) 
                        && (nickChanged ? regExpNick.test(this.userModalData.editUserQueryData.nick) : true)

      var regExpAccountId = /^[a-zA-Z0-9]{6,12}$/
      var accountIdCheck = accountIdChanged ? regExpAccountId.test(this.userModalData.editUserQueryData.account_id) : true

      var regExpPassword = /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/
      var passwordCheck = passwordChanged ? regExpPassword.test(this.userModalData.editUserQueryData.password) : true

      var regExpEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
      var emailCheck = emailChanged ? regExpEmail.test(this.userModalData.editUserQueryData.email) : true

      var regExpHp = /^\d{3}-\d{4}-\d{4}$/
      var hpCheck = hpChanged ? regExpHp.test(this.userModalData.editUserQueryData.hp) : true
      
      if(!fillDataCheck) {

        this.$vs.notify({
          title:'미입력',
          text:"빠짐없이 입력 하세요.",
          color:'warning'})

          return false;
      }
      
      if(!nickCheck) {

        this.$vs.notify({
          title:'닉네임',
          text:"닉네임은 6~12 글자, 공백 및 특수문자를 포함할 수 없습니다.",
          color:'warning'})
      }
      if(!accountIdCheck) {

        this.$vs.notify({
          title:'아이디',
          text:"아이디는 6~12 글자, 공백 및 특수문자를 포함할 수 없습니다.",
          color:'warning'})
      }
      if(!passwordCheck) {

        this.$vs.notify({
          title:'비밀번호',
          text:"비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다.",
          color:'warning'})
      }
      if(!emailCheck) {

        this.$vs.notify({
          title:'이메일',
          text:"올바른 형식의 이메일을 입력해주세요.",
          color:'warning'})
      }
      if(!hpCheck) {

        this.$vs.notify({
          title:'전화번호',
          text:"올바른 형식의 전화번호를 입력해주세요.",
          color:'warning'})
      }

      return nickCheck && accountIdCheck && passwordCheck && emailCheck && hpCheck
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },

  created() {
    
    const thisIns = this;

    this.$http
      .post("/api/manageUsers/commonlist", thisIns.renderMethod)
      .then(function(response) {
        
        thisIns.numOfUsers = response.data[0][0].user_count
        thisIns.numOfPages = parseInt(response.data[0][0].user_count / thisIns.renderMethod.itemsPerPage)
        thisIns.users = response.data[1]
      })
      .catch(function(error) {
        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          console.log(thisIns.$router)
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
        thisIns.$vs.notify({
          title: "Error",
          text: error,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
      });
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-common.scss";
</style>